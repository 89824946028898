import { useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import {
  withButtonAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import BpkSmallChevronLeft from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-left';
import BpkSmallChevronRight from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-right';
import { isRTL } from '@skyscanner/backpack-web/bpk-react-utils';

import STYLES from './DesktopScrollContainer.module.scss';

type DesktopScrollContainerProps = {
  nextLabel: string;
  prevLabel: string;
  children: JSX.Element[];
  logPrevEvent?: () => void;
  logNextEvent?: () => void;
  setVisibleIndexs?: (args: number[]) => void;
};
type ClickName = 'prev' | 'next';

const DEBOUNCE_TIME = 150;

const AlignedSmallChevronLeft = withButtonAlignment(
  withRtlSupport(BpkSmallChevronLeft),
);
const AlignedSmallChevronRight = withButtonAlignment(
  withRtlSupport(BpkSmallChevronRight),
);

const NextIcon = AlignedSmallChevronRight;
const PrevIcon = AlignedSmallChevronLeft;

/*
  Provide horizontal scrolling for cards in desktop device,
  - bpk-breakpoint-above-tablet: Display three cards on a page
  - bpk-breakpoint-tablet: Display two cards on a page

  Example:
      <DesktopScrollContainer>
        {cardList.map((card) => (
          <Card
            key={card.name}
          />
        ))}
      </DesktopScrollContainer>
*/
const DesktopScrollContainer = ({
  children,
  logNextEvent = undefined,
  logPrevEvent = undefined,
  nextLabel,
  prevLabel,
  setVisibleIndexs = undefined,
}: DesktopScrollContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const [containerWidth, setContainerWidth] = useState(0);
  // The index of the first card on the current page
  const [cardIndex, setCardIndex] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [pervDisabled, setPervDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);

  /*
  Calculate the number of cards that can be displayed on a page
  The +2 is to avoid the calculation error caused by clientWidth in rounding
  */
  const calculateNumberOfDisplay = (): number =>
    Math.floor((containerWidth + 2) / cardWidth);

  /*
  Judge whether to reach the last page
  params:
    nextCardIndex: Index of the first card on the next page
  */
  const touchEnd = (nextCardIndex: number): boolean =>
    (numberOfCards - nextCardIndex) * cardWidth <= containerWidth;

  /*
  Judge whether to reach the first page
  params:
    nextCardIndex: Index of the first card on the next page
  */
  const touchStart = (nextCardIndex: number): boolean => nextCardIndex <= 0;

  /*
  Judge whether to reach the first page
  params:
    xOffset: The relative offset of the X axis relative to the initial position
  */
  const setScrollPosition = (xOffset: number) => {
    if (!containerRef.current?.scroll) {
      return;
    }
    containerRef.current.scroll({
      left: isRTL() ? -xOffset : xOffset,
      behavior: 'smooth',
    });
  };

  // The number of all cards
  const numberOfCards = children.length;
  // The number of cards that can be displayed on a page
  let numberOfDisplay = calculateNumberOfDisplay();

  const isOnlyOnePage = numberOfDisplay >= numberOfCards;

  const debouncedGetWidth = debounce(() => {
    if (!containerRef.current) {
      return;
    }
    // Get the latest width of the container and card
    setContainerWidth(containerRef.current?.clientWidth || 0);
    setCardWidth(cardRef.current?.clientWidth || 0);

    // Recalculate and initialize component position
    numberOfDisplay = calculateNumberOfDisplay();
    setScrollPosition(0);
    setCardIndex(0);
    setPervDisabled(true);
    setNextDisabled(false);
  }, DEBOUNCE_TIME);

  // Initialize the container and card widths
  useEffect(() => {
    setContainerWidth(containerRef.current?.clientWidth || 0);
    setCardWidth(cardRef.current?.clientWidth || 0);
  }, []);

  // When the index of the first card on the page changes, the page is turned
  useEffect(() => {
    setScrollPosition(cardIndex * cardWidth);
  }, [cardIndex, cardWidth]);

  useEffect(() => {
    if (numberOfDisplay !== Infinity && numberOfDisplay > 0) {
      setVisibleIndexs &&
        setVisibleIndexs(
          Array.from(Array(numberOfDisplay), (_, i) => i + cardIndex),
        );
    }
  }, [cardIndex, numberOfDisplay, setVisibleIndexs]);

  // Listen for screen width
  useEffect(() => {
    window.addEventListener('resize', debouncedGetWidth);
    return () => {
      window.removeEventListener('resize', debouncedGetWidth);
    };
  });

  /*
    Handling page turns
    params:
      clikType: 'next' | 'prev'
  */
  const clickNav = (clikType: ClickName) => {
    if (!containerWidth || !cardWidth) {
      return;
    }
    if (clikType === 'next') {
      setPervDisabled(false);
      const nextCardIndex = cardIndex + numberOfDisplay;
      if (touchEnd(nextCardIndex)) {
        setNextDisabled(true);
        setCardIndex(numberOfCards - numberOfDisplay);
      } else {
        setCardIndex(nextCardIndex);
      }

      logNextEvent && logNextEvent();
    }
    if (clikType === 'prev') {
      const nextCardIndex = cardIndex - numberOfDisplay;
      setNextDisabled(false);
      if (touchStart(nextCardIndex)) {
        setPervDisabled(true);
        setCardIndex(0);
      } else {
        setCardIndex(nextCardIndex);
      }
      logPrevEvent && logPrevEvent();
    }
  };

  if (children.length === 0) {
    return null;
  }

  return (
    <div className={STYLES.DesktopScrollContainer}>
      {!isOnlyOnePage && (
        <div className={STYLES.DesktopScrollContainer__nav}>
          <BpkButton
            secondary
            onClick={() => {
              clickNav('prev');
            }}
            disabled={pervDisabled}
            aria-label={prevLabel}
          >
            <PrevIcon />
          </BpkButton>
          <BpkButton
            secondary
            onClick={() => {
              clickNav('next');
            }}
            disabled={isOnlyOnePage || nextDisabled}
            aria-label={nextLabel}
          >
            <NextIcon />
          </BpkButton>
        </div>
      )}
      <div
        data-testid="container"
        className={STYLES.DesktopScrollContainer__container}
        ref={containerRef}
      >
        {children.map((card, index) => (
          <div
            key={`card-${index + 1}`}
            className={STYLES.DesktopScrollContainer__card}
            ref={cardRef}
          >
            {card}
          </div>
        ))}
      </div>
    </div>
  );
};
export default DesktopScrollContainer;
